import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { colors, mediaQuery } from "../../../theme"
import { Link } from "gatsby"
import { ReactComponent as ArrowRight } from "../../../images/arrow-right.svg"

const CardContainer = styled(Link)`
  max-width: 352px;
  min-height: 382px;
  background: #eef1fb;
  box-shadow: 0px 0px 20px rgba(241, 120, 182, 0.2);
  border-radius: 15px;

  text-decoration: "none";
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  justify-self: center;
  align-self: start;
  height: 100%;
  margin-bottom: 32px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQuery("md")} {
    margin: 0 0 48px 0;
  }
`

const Image = styled.div`
  margin-bottom: 25px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 250px;
    border-radius: 15px 15px 0px 0px;
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.726px;
    color: #0d152e;
    margin-bottom: 16px;
  }
`

const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 32px;
  box-sizing: border-box;

  p {
    font-size: 16px;
    color: #416ff4;
    line-height: 21px;
    letter-spacing: -0.528px;
    text-overflow: ellipsis;
    transition: color 0.1s ease-in-out, font-size 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #1c3170;
      font-size: 17px;
    }
  }
  svg {
    transition: transform 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.3, 1.3);
    }
  }
`

const Paragraph = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  position: relative;
  max-height: 84px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #81838c;
    width: 100%;
  }
`

export const UsefulInformationCard = ({
  content,
  heading,
  image,
  slug,
  id,
  hidden,
}) => {
  return (
    <CardContainer hidden={hidden} to={`/pasiulymai/${slug}`}>
      <div>
        <Image>
          <GatsbyImage image={image} />
        </Image>
        <Text>
          <h5>{heading}</h5>
          <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
        </Text>
      </div>
      <CardButton>
        <Link
          to={`/pasiulymai/${slug}`}
          style={{
            textDecoration: "none",
          }}
          id={id}
        >
          <p>Skaityti daugiau</p>
        </Link>

        <Link
          to={`/pasiulymai/${slug}`}
          style={{
            textDecoration: "none",
            display: hidden ? "none" : "flex",
          }}
          id={id}
        >
          <ArrowRight />
        </Link>
      </CardButton>
    </CardContainer>
  )
}
