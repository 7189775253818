import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"

const Text = styled.p`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1.584px;
  color: #0d152e;
  text-align: center;

  strong {
    color: #ef5da8;
  }

  ${mediaQuery("md")} {
    font-size: 40px;
  }
`

export const Headline = ({ children }) => {
  return <Text>{children}</Text>
}
