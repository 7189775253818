import * as React from "react"
import "reset-css"
import { Layout } from "../components/layout"
import { Hero } from "../components/hero"
import { GlobalCss } from "../theme"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import { WhyUs } from "../components/why-us"
import { UsefulInformation } from "../components/useful-information"
import { Services } from "../components/services/services"

const IndexPage = ({ data: { wpPage } }) => {
  return (
    <>
      <Seo post={wpPage} />
      <GlobalCss />
      <Layout>
        <Hero />
        <WhyUs />
        <Services />
        <UsefulInformation />
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
export default IndexPage
