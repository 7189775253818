import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 366px;
  margin-top: 64px;
`
const Title = styled.h4`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.66px;
  color: ${colors.black1};
  margin-bottom: 8px;
`
const Text = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.528px;

  color: ${colors.gray2};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const ImageContainer = styled.div`
  width: 40px;
  text-align: center;
  margin-right: 16px;
`

export const WhyUsCard = ({ card }) => {
  return (
    <CardContainer>
      <ImageContainer>
        <img src={card.image}></img>
      </ImageContainer>
      <Content>
        <Title>{card.title}</Title>
        <Text>{card.text}</Text>
      </Content>
    </CardContainer>
  )
}
