import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container } from "../../theme/helpers"
import { UsefulInformationCard } from "./useful-information-card"
import { Button } from "../button"
import { mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { graphql, useStaticQuery } from "gatsby"
import { getMappedNewsCards } from "../../core/data.mappers"
import { getImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  ${mediaQuery("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mediaQuery("lg")} {
    grid-template-columns: 1fr;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`

const Section = styled.section`
  margin-top: 48px;
`

export const UsefulInformation = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost {
        nodes {
          content
          slug
          title
          date(formatString: "YYYY MMMM DD", locale: "lt")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const newsCards = getMappedNewsCards(data)
  const [count, setCount] = useState(3)

  useEffect(() => {
    if (count === 3) {
      return
    }

    count > 3 && scrollTo(`#news-card-${count - 3}`, "start")
  }, [count])

  return (
    <Container id="news">
      <Section>
        <Headline>Pasiūlymai</Headline>

        <CardsContainer>
          {newsCards.reverse().map((card, index) => (
            <UsefulInformationCard
              heading={card.title}
              date={card.date}
              image={getImage(card.image)}
              content={card.content}
              slug={card.slug}
              id={`news-card-${index + 1}`}
              hidden={index + 1 > count}
            />
          ))}
        </CardsContainer>
        <ButtonContainer>
          <Button
            isBlue
            onClick={() => {
              setCount(count + 3)
            }}
            disabled={count >= newsCards.length}
          >
            Žiūrėti daugiau
          </Button>
        </ButtonContainer>
      </Section>
    </Container>
  )
}
