import React from "react"
import styled from "styled-components"
import { Container } from "../../theme/helpers"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { colors, mediaQuery } from "../../theme"
import { graphql, useStaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { ReactComponent as CloverSvg } from "../../images/clover.svg"
import { Button } from "../button"

const HeroContainer = styled.section`
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  background: #101c3d;

  strong {
    color: #ef5da8;
  }

  svg {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  ${mediaQuery("lg")} {
    flex-direction: column;
    height: 100%;

    h1 {
      margin: 32px 0;
    }
    padding-bottom: 32px;
  }
`
const HeroImageContainer = styled.div`
  width: 37%;
  height: 100vh;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${mediaQuery("lg")} {
    width: 100%;
    height: 50vh;
    z-index: 2;
  }
`

const Heading = styled.h1`
  font-family: DM Sans;
  font-weight: bold;
  font-size: 60px;
  line-height: 115%;
  letter-spacing: -1.88px;
  color: ${colors.white};
  margin-bottom: 22px;

  ${mediaQuery("md")} {
    font-size: 40px;
  }

  ${mediaQuery("sm")} {
    font-size: 33px;
  }
`

const Paragraph = styled.div`
  p {
    font-family: DM Sans;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.66px;
    color: #fff;
    margin-bottom: 32px;
  }
`
const Text = styled.div`
  z-index: 1;
  position: absolute;
  left: 45%;
  max-width: 420px;
  margin-right: 100px;

  ${mediaQuery("lg")} {
    width: unset;
    max-width: unset;
    margin: 0 32px;
    position: static;
  }

  ${mediaQuery("md")} {
    font-size: 14px;
  }

  ${mediaQuery("sm")} {
    font-size: 12px;
  }
`

export const Hero = () => {
  const {
    wpPage: { ACF_homepage },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          header {
            title
            text
            buttonText
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 1024)
                }
              }
            }
          }
        }
      }
    }
  `)
  const title = ACF_homepage.header.title
  const text = ACF_homepage.header.text
  const button_text = ACF_homepage.header.buttonText
  const image = ACF_homepage.header.image.localFile

  return (
    <HeroContainer id="page-top">
      <HeroImageContainer>
        <GatsbyImage image={getImage(image)} />
      </HeroImageContainer>

      <Text>
        <Heading
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />

        <Button isBlue onClick={() => scrollTo("#form", "center")}>
          <span>{button_text}</span>
        </Button>
      </Text>
      <CloverSvg className="cloverSvg" />
    </HeroContainer>
  )
}
