import React from "react"
import styled from "styled-components"
import { Container, mediaQuery } from "../../theme"
import { Headline } from "../headline"
import { ServicesCard } from "./services-card"
import { graphql, useStaticQuery } from "gatsby"

const ServicesContainer = styled.div`
  padding: 48px 0;
`

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  ${mediaQuery("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${mediaQuery("lg")} {
    grid-template-columns: 1fr;
  }
`

export const Services = () => {
  const {
    wpPage: {
      ACF_homepage: { services },
    },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          services {
            title
            text
            image {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  return (
    <ServicesContainer>
      <Container>
        <Headline>Paslaugų sąrašas</Headline>
        <CardsContainer>
          {services.map((service, index) => (
            <ServicesCard data={service} key={index} />
          ))}
        </CardsContainer>
      </Container>
    </ServicesContainer>
  )
}
