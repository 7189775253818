import React from "react"
import styled from "styled-components"
import { colors } from "../../theme"
import { Button } from "../button"
import { ReactComponent as SampleSvg } from "../../images/service-card.svg"
import scrollTo from "gatsby-plugin-smoothscroll"

const CardContainer = styled.div`
  max-width: 360px;
  box-sizing: border-box;
  padding: 32px 50px;
  background: #eef1fb;
  border-radius: 10px;
  justify-self: center;
  align-self: start;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-bottom: 16px;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: ${colors.black1};
    margin-bottom: 16px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #182748;
    margin-bottom: 32px;
  }
`

export const ServicesCard = ({ data }) => {
  return (
    <CardContainer>
      <div>
        <img src={data.image.localFile.publicURL}></img>
        <h2>{data.title}</h2>
        <p>{data.text}</p>
      </div>
      <Button onClick={() => scrollTo("#form", "center")} isBlue>
        Pateikti užklausą
      </Button>
    </CardContainer>
  )
}
