import React from "react"
import { Headline } from "../headline"
import { WhyUsCard } from "../why-us-card/why-us-card"
import { colors, Container, mediaQuery } from "../../theme"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { getMappedWhyUsCards } from "../../core/data.mappers"
import { Button } from "../button"

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 96px;
  ${mediaQuery("xl")} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 48px 0;
`
export const WhyUs = () => {
  const {
    wpPage: { ACF_homepage },
  } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ACF_homepage {
          whyUs {
            title
            text
            image {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const cards = getMappedWhyUsCards(ACF_homepage.whyUs)
  console.log(cards)
  return (
    <Container>
      <CardsContainer>
        <Headline>
          Kodėl verta rinktis <strong>mus</strong>?
        </Headline>

        {cards.map((card, index) => (
          <WhyUsCard card={card} key={index} />
        ))}
      </CardsContainer>
      <ButtonContainer>
        <Link to="/apie-mus">
          <Button isBlue>Apie mus</Button>
        </Link>
      </ButtonContainer>
    </Container>
  )
}
